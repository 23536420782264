.fa.fa-logo-black-icon.bse:before {
  content: "";
  display: block;
  background: url("https://www.bseindia.com/include/images/bselogo.png");
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  color: white;
  background-repeat: no-repeat;
}

.fa.fa-logo-white-icon.bse:before {
  content: "";
  display: block;
  background: url("https://www.bseindia.com/include/images/bselogo.png");
  width: 100px;
  height: 100px;
  background-position: center;
  background-size: contain;
  color: white;
  background-repeat: no-repeat;
}

.fa.fa-logo-black-icon.geidea:before {
  content: "";
  display: block;
  background: url("/logo_web_geidea.png?v=1");
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: cover;
  color: white;
}

.fa.fa-logo-white-icon.geidea:before {
  content: "";
  display: block;
  background: url("/logo_web_white_geidea.png?v=1");
  width: 90px;
  height: 90px;
  background-position: center;
  background-size: cover;
  color: white;
}
/* 
.fa.fa-logo-black-icon:before {
    content: "";
    display: block;
    background: url("/logo_web.png?v=1");
    width: 152px;
    height: 56px;
    background-position: center;
    background-size: cover;
    color: white;
  }

  .fa.fa-logo-white-icon:before {
    content: "";
    display: block;
    background: url("/logo_web_white.png?v=1");
    width: 152px;
    height: 56px;
    background-position: center;
    background-size: cover;
    color: white;
  }
 
  .fa.fa-logo-aware-icon:before {
    content: "";
    display: block;
    background: url("/aware_icon.png?v=1");
    width: 30px;
    height: 44px;

    background-position: center;
    background-size: cover;
    color: white;
  }
   */
  .fa.fa-logo-aware-icon.geidea:before {
    content: "";
    display: block;
    background: url("/geidea_icon.png?v=1") ; 
    width: 40px;
    height: 45px;

    background-position: center;
    background-size: cover;
    color: white;
  }

  .fa.fa-logo-aware-icon.bse:before {
    content: "";
    display: block;
    background: url("https://www.bseindia.com/include/images/bselogo.png") ; 
    width: 45px;
    height: 45px;

    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.grab:before {
    content: "";
    display: block;
    background: url("https://grab.in/image/logo.png") ; 
    width: 45px;
    height: 45px;

    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.grab:before {
    content: "";
    display: block;
    background: url("https://grab.in/image/logo.png");
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.grab:before {
    content: "";
    display: block;
    background: url("https://grab.in/image/logo.png");
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.exordiumlearning:before {
    content: "";
    display: block;
    background: url("/ex_logo_text_half.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.exordiumlearning:before {
    content: "";
    display: block;
    background: url("/Exn_logo_NoBG.png?v=2");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.exordiumlearning:before {
    content: "";
    display: block;
    background: url("/Exn_logo_NoBG.png?v=2");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.qa-learning:before {
    content: "";
    display: block;
    background: url("/QA-logo.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.qa-learning:before {
    content: "";
    display: block;
    background: url("/QA-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.qa-learning:before {
    content: "";
    display: block;
    background: url("/QA-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.aubanklearning:before {
    content: "";
    display: block;
    background: url("/AU-Bank-logo.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.aubanklearning:before {
    content: "";
    display: block;
    background: url("/AU-Bank-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.aubanklearning:before {
    content: "";
    display: block;
    background: url("/AU-Bank-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.ipmasterlearning:before {
    content: "";
    display: block;
    background: url("/ipmasters.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.ipmasterlearning:before {
    content: "";
    display: block;
    background: url("/ipmasters.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.ipmasterlearning:before {
    content: "";
    display: block;
    background: url("/ipmasters.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.netcomlearning:before {
    content: "";
    display: block;
    background: url("/Netcom-logo.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.netcomlearning:before {
    content: "";
    display: block;
    background: url("/Netcom-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.netcomlearning:before {
    content: "";
    display: block;
    background: url("/Netcom-logo.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

   .fa.fa-logo-aware-icon.cialearning:before {
    content: "";
    display: block;
    background: url("/CIA-Manager.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.cialearning:before {
    content: "";
    display: block;
    background: url("/CIA-Manager.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.cialearning:before {
    content: "";
    display: block;
    background: url("/CIA-Manager.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.silearning:before {
    content: "";
    display: block;
    background: url("/logo_menu_white.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.silearning:before {
    content: "";
    display: block;
    background: url("/logo_si_white.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.silearning:before {
    content: "";
    display: block;
    background: url("/logo_si_white.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.asflearning:before {
    content: "";
    display: block;
    background: url("/asf_logo_small.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.asflearning:before {
    content: "";
    display: block;
    background: url("/asf_logo.png?v=1");
    width: 330px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.asflearning:before {
    content: "";
    display: block;
    background: url("/asf_logo.png?v=1");
    width: 330px;
    height: 74px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon.integralearning:before {
    content: "";
    display: block;
    background: url("/logo_wn.png?v=1");
    width: 45px;
    height: 45px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon.integralearning:before {
    content: "";
    display: block;
    background: url("/logo_iss.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon.integralearning:before {
    content: "";
    display: block;
    background: url("/logo_iss.png?v=1");
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-aware-icon:before {
    content: "";
    display: block;
    background: url("/Cyberseck_menu_logo.png?v=1");
    width: 36px;
    height: 44px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-black-icon:before {
    content: "";
    display: block;
    background: url("/Cyberseck_logo.png?v=1");
    width: 205px;
    height: 70px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }

  .fa.fa-logo-white-icon:before {
    content: "";
    display: block;
    background: url("/Cyberseck_logo.png?v=1");
    width: 205px;
    height: 70px;
    background-position: center;
    background-size: contain;
    color: white;
    background-repeat: no-repeat;
  }